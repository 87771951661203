import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { css } from 'emotion'
import PostHeader from 'components/PostHeader'
import Layout from 'layout/layout'
import styled from 'styled-components'
import theme from 'utils/theme'
import SEO from "components/seo"

import ShareIcon from 'assets/social-icons/icon-share.svg'
import LinkedInIcon from 'assets/social-icons/icon-linkedin.svg'
import TwitterIcon from 'assets/social-icons/icon-twitter.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
`;

const BlogPost = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 40px 0 56px 0;

  ${theme.responsiveKeys.mobile} {
    margin: 0 0 24px 0;
  }
`;

const PostContent = styled.div`
  margin-top: 2em;

  ${theme.responsiveKeys.mobile} {
    margin-top: 1.56em;
  }
`;

const TagSectionStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `0`,
}

const TagStyle = {
  display: `flex`,
  justtifyContent: `center`,
  margin: `0 8px 8px 0`,
  padding: `8px 16px`,
  borderRadius: `20px`,
  backgroundColor: theme.colors.black20,
  width: `fit-content`,

  [`${theme.responsiveKeys.mobile}`]: {
    padding: `6px 12px`,
    borderRadius: `16px`,
  },
}

const TagTextStyle = {
  fontSize: `14px`,
  lineHeight: `1.2em`,
  fontWeight: theme.fontWeight.medium,
  margin: 0,
  padding: 0,
  color: theme.colors.black80,
}

const PostFooterStyle = {
  width: `100%`,
  float: `left`,
  margin: `0 0 0 0`,
}

const SocialSectionStyle = {
  display: `flex`,
  justifyContent: `flex-end`,
  margin: `8px 0 0 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `16px 0 16px 0`,
    justifyContent: `center`,
  },
}

const PostBreakStyle = {
  margin: `40px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `24px 0`,
  },
}

const SocialOutletStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignContent: `center`,
  backgroundColor: theme.colors.white,
  borderRadius: `8px`,
  margin: `0`,
  padding: `0 0 0 24px`,

  [`${theme.responsiveKeys.mobile}`]: {
    // backgroundColor: theme.colors.black05,
    margin: `0 8px`,
    padding: `16px`,
    borderRadius: `32px`,
  },
}

const SocialLinkStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignContent: `center`,
  color: theme.colors.white,
  cursor: `pointer`,
}

const HRStyle = {
  width: `100%`,
}

const CopyLinkToastStyle = {
  display: `flex`,
  justifyContent: `flex-end`,
  margin: `-28px 0 16px 0`,
  fontWeight: theme.fontWeight.semibold,
  fontFamily: `var(--title-font-family)`,
  textTransform: `uppercase`,
  color: theme.colors.black90,
  fontSize: theme.fontSize.xsmall,
  lineHeight: `1em`,
  letterSpacing: `1px`,
  textAlign: `center`,
  transition : `all .3s`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `8px 0 0 0`,
    justifyContent: `center`,
  },
}

class BlogPostTemplate extends React.Component {
  
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      copied: false,
      showCopyConfirmation: false
    }

    this.handleLinkClicked = this.handleLinkClicked.bind(this)
    this.showToast = this.showToast.bind(this)
  }

  handleLinkClicked() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const deconstructingDesignURL = `https://deconstructingdesign.com`
    const fullLink = `${deconstructingDesignURL}/blog/${post.slug}`

    this.setState({
      value: fullLink,
      copied: true,
    })

    navigator.clipboard.writeText(fullLink)

    this.showToast()
  }

  showToast() {
    this.setState({
      showConfirmation: true
    });

    setTimeout(function() {
      this.setState({
        showConfirmation: false,
      });
    }.bind(this), 3000)
  };

  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const deconstructingDesignURL = `https://deconstructingdesign.com`
    const postTitle = post.title
    const fullLink = `${deconstructingDesignURL}/blog/${post.slug}`

    const linkedInLink = `https://www.linkedin.com/shareArticle?mini=true&url=${fullLink}/&title=${postTitle}&source=${deconstructingDesignURL}`

    const opacity = this.state.showConfirmation ? 1 : 0
    const ToastStyle = {
      opacity: opacity
    }

    return (
      <Layout location={this.props.location} >
        <div className="container">
          <div className="row">
            <Wrapper>
              <BlogPost className={`blog-post`}>
                <SEO title={`${post.title}`} />
                <div className="col-12">
                  <PostHeader
                    title={post.title}
                    publicationDate={post.publicationDate}
                    timeToRead={post.content.childMarkdownRemark.timeToRead}
                  />
                </div>
                <div className="col-12">
                  <PostContent
                    dangerouslySetInnerHTML={{
                      __html: post.content.childMarkdownRemark.html,
                    }}
                    >
                  </PostContent>
                </div>
                <div className="col-12 col-12-sm">
                  <div className={css(PostBreakStyle)}>
                    <hr style={HRStyle} />
                  </div>
                </div>
                <div style={PostFooterStyle}>
                  <div className="col-8 col-12-sm">
                    <div style={TagSectionStyle}>
                      {post.tags.map(tag => (
                        <div className={css(TagStyle)} key={tag}>
                          <p style={TagTextStyle}>
                            {tag}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-4 col-12-sm">
                    <span className={css({...CopyLinkToastStyle, ...ToastStyle})}>Copied! 👍</span>
                    <div className={css(SocialSectionStyle)}>
                      <div className={css(SocialOutletStyle)}>
                        <a style={SocialLinkStyle} href={`https://twitter.com/intent/tweet?status=${post.title} by Deconstructing Design ${fullLink}`}><img src={TwitterIcon} alt={`Share on Twitter`} /></a>
                      </div>
                      <div className={css(SocialOutletStyle)}>
                        <a style={SocialLinkStyle} href={linkedInLink} target={`_blank`}><img src={LinkedInIcon} alt={`Share on LinkedIn`} /></a>
                      </div>
                      <div className={css(SocialOutletStyle)}>
                        <span style={SocialLinkStyle} onClick={this.handleLinkClicked}><img src={ShareIcon} alt={`Share the link`} /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </BlogPost>
            </Wrapper>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      snippet
      slug
      publicationDate(formatString: "MMMM Do, YYYY")
      tags
      content {
        childMarkdownRemark {
          id
          html
          timeToRead
          wordCount {
            words
          }
        }
      }
    }
  }
`
