import React from "react"
import styled from 'styled-components'

import theme from 'utils/theme'

const PostHeaderContainer = styled.div`
  margin-bottom: 0.5em;
`

const PostTitle = styled.h1`
`;

const PostInfo = styled.p`
  color: ${theme.colors.black60};
  font-size: ${theme.fontSize.medium};
  line-height: 1.2em;
  margin: 12px 0 0 0;
`

const PostHeader = ( props ) => (
  <PostHeaderContainer>
    <PostTitle>{ props.title }</PostTitle>
    <PostInfo>{ `${props.publicationDate} ∙ ${props.timeToRead} min read` }</PostInfo>
  </PostHeaderContainer>
)

export default PostHeader
